import auth from '@/actions/authorizations/index.js'

export default class {
  execute(spec, component) {
    const t = TrelloPowerUp.iframe()
    const formData = spec.formData

    $tpu.urls.preloadBoard(t).then((board) => {
      const value = formData['setting[auto_launch]'] || ""
      localStorage.setItem(`tpu.${board.id}.autoLaunch`, value)

      auth.requestRelaunch(t, value)

      GLib.action.execute({
        "action": "snackbars/alert",
        "message": "Saved",
        "styleClasses": [
          "success"
        ]
      }, component);

      GLib.action.execute(spec.onSuccess, component);
    })
  }
}
